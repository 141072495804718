.login-page {
    background-color: #f2ecf5;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .login-form-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 40px;
    width: 400px;
  }
  
  .login-title {
    color: #702a72;
    margin-top: 0;
  }
  
  .login-subtitle {
    color: #333333;
    margin-bottom: 40px;
  }
  
  .phone-input {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #cccccc;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .login-button {
    background-color: #702a72;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .login-form-container {
      width: 80%;
      padding: 20px;
    }
  }
  
  
  
  
  
  
  
  