* {
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
}

.App h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.App h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.App h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.App h4 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.App input[type="file"] {
  margin-bottom: 1rem;
}

.App button {
  background-color: #702a72;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.App button:hover {
  background-color: #914f94;
}


/* Change the background color of the active tab */
.tools-tabs .nav-item .nav-link.active {
  background-color: #702a72;
  color: #fff;
  font-weight: bold;
}

/* Change the background color and font color of the non-active tabs */
.tools-tabs .nav-item .nav-link {
  background-color: #f8f9fa;
  color: #000;
}

/* Change the background color and font color when hovering over non-active tabs */
.tools-tabs .nav-item .nav-link:hover {
  background-color: #e0e0e0;
  color: #000;
}