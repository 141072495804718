.manage-hashavshevet {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.hashavshavet-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 300px;
}