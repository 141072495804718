.photo-element {
    position: relative;
    width: 300px; /* Adjust size as needed */
    border: 1px solid black; /* This sets the border width, style, and color */
    border-radius: 15px;
    overflow: hidden;
}

.venue-photo {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 25px;
}

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.btn {
    border: none;
    background-color: transparent;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.btn.approve {
    color: white;
    background-color: green;
}

.btn.delete {
    color: white;
    background-color: red;
}

.btn.new-tab {
    color: black;
    background-color: white;
}

.info {
    background-color: white;
    padding: 10px;
    text-align: center;
}

.info h2,
.info p {
    margin: 5px 0;
}
