.grid-boxes-container {
    background-color: #4f4072;
    color:white;
    padding-top: 15px;
    .section {
        
        
        padding: 15px;
        .section-header {
            margin-bottom: 10px;
            text-align: right;
            
            font-size: x-large;
        }
        .boxes {
            display: flex;
            flex-wrap: wrap;

            .grid-box {
                font-family: 'Tajawal', sans-serif;
                width: 458px;
                height: 150px;
                border-radius: 12px;
                background: #190845;
                position: relative;
                display: flex;
                cursor: pointer;
                margin-left: 15px;
                margin-bottom: 15px;
                align-items: center;

                .grid-box-image {
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    bottom: 30px;
                    left: 24px;
                    filter: invert(48%) sepia(92%) saturate(1456%) hue-rotate(106deg) brightness(98%) contrast(101%);
                }

                .grid-box-title {
                    font-size: 32px;
                    position: absolute;
                    bottom: 60px;
                    right: 16px;
                    color: white;
                }
                .grid-box-title2 {
                    font-size: 20px;
                    position: absolute;
                    bottom: 30px;
                    right: 16px;
                    color: white;
                }
            }
            .grid-box::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 12px;
                
                background-color: rgba(250, 235, 215, 0); /* Start with transparent background */
                transition: background-color 0.3s ease; /* Smooth transition */
            }

            .grid-box:hover::before {
                background-color: rgba(248, 237, 222, 0.2); /* Slight color overlay on hover */
                border-radius: 12px;
                border: solid 1px rgb(0, 255, 4);
            }
        }
    }
    
}

