.form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.form-container form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.form-container label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
}

.form-container label span {
    margin-bottom: 5px;
}

.form-container input[type="number"],
.form-container input[type="text"] {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-container button:hover {
    background-color: #0056b3;
}
