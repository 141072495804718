.h3-title {
    color: #7f7f7f;
}

.error {
    border: 1px solid red;
}

.fst-row {
    align-items: center;
    display: flex;
    .dropdown {
        text-align: center;
        border-radius: 5px;
        height: 56px;
    }
}
.input-text {
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.action-buttons {
    margin-top: 25px;
    text-align: left;
}

.dialog-bottom {
    text-align: right;
    font-weight: bold;
    padding: 10px;
    color: #702a72;
    font-size: large;
}
.small-titles {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
}

.error-class {
    border: 1px solid red;
}

.form-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
  

    .form-header-left {
  
        text-align: left;
       
    }
    .form-header-right {
        text-align: right;
       
    }
}
