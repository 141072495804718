.header{
    text-align: right;
    margin-bottom: 30px;
    margin-top: 30px;
    
}
.covers-all {
    display: flex;
}

.photo-cover {
    position: relative;
    cursor: pointer;
    margin-left: 30px;
}

.photo-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 235, 215, 0);
    transition: background-color 0.3s ease;
}

.photo-cover:hover::before {
    background-color: rgba(250, 235, 215, 0.3);
}

.pdf-cover {
    width: 220px;
    height: 320px;
}
.firewall-sec {
    text-align: right;
    
}
.firewall{
    cursor: pointer;
    display: inline-block;
    
}

.steps {
    margin-top: 40px;
    
    text-align: right;
    
 
}
.steps-cover{
    
    cursor: pointer;
    text-align: right;
    display: inline-block;
}
