.two-sides {
    display: flex;
    padding: 30px;
    align-items: center;


    .right-side {
        margin: auto;
    }

    .left-side {
        
        margin: auto;

        .box-container {
            font-family: 'Tajawal', sans-serif;
            width: 398px;
            height: 150px;
            border-radius: 12px;
            background: linear-gradient(var(--topColor),var(--bottomColor) );
            position: relative;
            display: flex;
            
           
          }
          
          .box-title {
            font-size: 24px;
            position: absolute;
            bottom: 42px;
            right: 16px;
            color: white;
          }
          
          .box-image {
            width: 108px;
            height: 108px;
            position: absolute;
            bottom: 21px;
            left: 24px;
          }
          

    }


}

.categoryishiddenfalse{

  color:rgb(49, 174, 17);
  font-size: x-large;
}
.categoryishiddentrue{

  color:rgb(173, 18, 18);
  font-size: x-large;
}