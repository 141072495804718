.sidebar {
    width: 220px;
    height: 100%;
    position: fixed;
    background-color: #E9C0E9;
    padding: 5px;
    padding-top: 20px;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.16), 3px 0 6px rgba(0, 0, 0, 0.10);
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer and Edge */
    user-select: none; /* Standard syntax */
    z-index: 1; /* Add this line to set the z-index of the sidebar */
  }

  .hamburger {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: fixed; /* Change from absolute to fixed */
    right: 5px;
    top: 5px;
    z-index: 2;
    color: #fb1c1c; /* Add this line to set the color of the hamburger button */
  }

  .sidebar.visible {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .sidebar nav ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar nav ul li {
    margin-bottom: 1rem;
  }
  
  .sidebar nav ul li a {
    text-decoration: none;
    color: #000000;
  }
  
  .sidebar nav ul li a.active {
    font-weight: bold;
    color: #ffffff;
    background-color: #502274;
    border-radius: 10PX;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .sidebar:not(.visible) nav {
    
    transition: transform 0.3s ease-in-out;
    width: 0;
  }
  
  .sidebar-visible .main-content {
    margin-right: 200px;
    padding: 20px;
    width: calc(100% - 200px);
    flex-grow: 1;
    transition: margin-right 0.3s ease-in-out;
  }

  .version-text {
    color: #444;
  }