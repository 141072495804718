.table-header {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
}


.input-text {
    align-items: center;
    
}
.input-text-dropdown {
    height: 56px;
    border-radius: 5px;
    text-align: center;
}


.machine-details-editor-header{
    display: flex;

    .website-select-sec{
        margin-left: 311px;
        .branch-select {
            height: 30px;
            border-radius: 5px;
        }

    }

    .machine-select {
        .branch-select {
            height: 30px;
            border-radius: 5px;
        }

    }
}


