.table-contener {
    
    text-align: right;
    border: solid 1px black;
    border-radius: 5px;
    width: 500px;
    background-color: rgb(213, 202, 208);

    .table-row {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 500px;
    }
}