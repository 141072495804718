* {
  box-sizing: border-box;
  text-align: right;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.select-with-label > select {
  border: 1px solid #ccc;
  padding: 3px;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('/public/Tajawal-APPLICATION-FONT/Tajawal-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


body {
  font-family: 'Tajawal', sans-serif;
}

.box {
  border-radius: 5px;
  margin: 10px 0;
  padding: 15px 0;
  box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.35);
  -webkit-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.35);
}

.error-text {
  color: red;
}


.mb-1 {
  margin-bottom: 5px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.mt-1 {
  margin-top: 5px !important;
}

.mt-2 {
  margin-top: 10px !important;
  align-items: center;
}

.flex {
  display: flex !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.pr-2 {
  padding-right: 10px !important;
}

.width-300 {
  width: 300px;
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

span {
  display: block;
}

.width-150 {
  width: 150px !important;
}

.width-400 {
  width: 400px;
}

.leaflet-attribution-flag, .leaflet-control-attribution {
  display: none !important;
  opacity: 0 !important;
}

.width-350 {
  width: 400px;
}

.width-500 {
  width: 500px;
}

.width-600 {
  width: 600px;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: #f2ecf5;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

.App {
  text-align: center;
  padding: 2rem;
  position: relative;
  background-color: #f2ecf5;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
}

.main-content {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app-grid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar-visible .main-content {
  margin-right: 200px;
  padding: 20px;
  width: calc(100% - 200px);
  flex-grow: 1;
}


.App h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.App h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.App h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.App h4 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.App input[type="file"] {
  margin-bottom: 1rem;
}

.App button {
  background-color: #702a72;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.App button:hover {
  background-color: #914f94;
}
.App .buttonD {
  background-color: #ffffff;
  border: 2px solid #702a72;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.App .buttonD:hover {
  background-color: #ce1616;
  border: 2px solid #ce1616;
  color: white;
}
.App .buttonA {
  background-color: #acd382;
  border: 2px solid #acd382;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.App .buttonA:hover {
  background-color: #74B72E;
  border: 2px solid #74B72E;
  color: white;
}
.App .buttonH {
  background-color: #acd382;
  border: 2px solid #acd382;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.App .buttonH:hover {
  background-color: #74B72E;
  border: 2px solid #74B72E;
  color: white;
}

.exit-no-select {
  align-items: center;
  border: 1px solid #702a72;
  border-radius: 32px;
  box-shadow: 0 3px 8px 0 rgba(0,23,55,.2);
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  height: 32px;
  min-width: 32px;
  justify-content: center;
  width: 32px;
  padding: 0;
  text-align: center;
  transition-property: background, color;
  transition-delay: 0s, 0s;
  transition-timing-function: ease, ease;
  transition-duration: 0.4s, 0.4s;
  user-select: none;
  -webkit-user-drag: none;
}

.exit-no-select:hover {
  color: white;
  background-color: #702a72;
}
