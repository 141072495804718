.manage-balances-container {
    text-align: right;
    padding: 30px;
}
.generic-select, .search-input {
    width: 300px;
}

.manage-balances-row {
    background-color: #EEE;
}